* {
  font-family: 'Rubik', monospace;
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  margin: 0;
  background-color: #151922;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: top left;
  background-repeat: repeat;
}

#root{
  position: relative;
  height: inherit;
}

@media only screen and (max-width: 31.25rem) {
  html, body {
    overflow: hidden;
  }
  body {
    overflow: auto;
  }
}

.Toastify__toast-body {
  padding: 0!important;
}